<template>
    <div>
        <img style="height: 1em;" src="../../assets/img/tsviewer/server.png" /> {{name}}
        <div style="padding-left: 1.1em;">
            <TeamspeakViewerChannel v-for="ch in channelTree" :key="ch.id" :channel="ch" />
        </div>
    </div>
</template>

<script>
import TeamspeakViewerChannel from './TeamspeakViewerChannel'

export default {
    components: { TeamspeakViewerChannel },
    props: ['name', 'channels', 'clients'],
    methods: {
        buildTree(channels, clients, channelId = 0){
            if(channelId == 0)
                return this.sortChannels(channels.filter(c => c.parent_id == channelId)).map(c => this.buildTree(channels, clients, c.id))
            return {
                id: channelId,
                order: channels.find(c => c.id == channelId).order,
                name: channels.find(c => c.id == channelId).name,
                clients: clients.filter(c => c.channel_id == channelId),
                channels: this.sortChannels(channels.filter(c => c.parent_id == channelId)).map(c => this.buildTree(channels, clients, c.id))
            }
        },
        sortChannels(channels){
            if(channels.length == 0)
                return channels;
            let sorted = []
            let last = channels.find(c => c.order == 0)
            sorted.push(last)
            while(channels.length != sorted.length){
                last = channels.find(c => c.order == last.id)
                sorted.push(last)
            }
            return sorted
        }
    },
    computed: {
        channelTree(){
            return this.buildTree(this.channels, this.clients)
        }
    }
}
</script>