<template>
    <datatable ref="table" nopagination :path="'/teamspeak-servers/' + $route.params.id + '/tokens'" :headers="[
        {text: 'Token', value: 'token'},
        {text: 'Beschreibung', value: 'description'},
        {text: 'Aktionen', value: 'actions'}
    ]">
        <template v-slot:actions>
            <v-dialog max-width="600px" v-model="open">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on">Neuer Berechtigungsschlüssel</v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline">Neuer Berechtigungsschlüssel</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-text-field label="Beschreibung" v-model="description" />
                            <v-select label="Gruppe" v-model="groupId" :items="groups" item-text="name" item-value="id" />
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="open = false">Abbrechen</v-btn>
                        <v-btn @click="createToken()" color="primary">Erstellen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-btn small color="red" class="white--text" @click="deleteToken(item.token)">Löschen</v-btn>
        </template>
    </datatable>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'

export default {
    data() {
        return {
            open: false,
            groupId: undefined,
            description: ''
        }
    },
    props: {
        groups: {
            type: Array
        }
    },
    methods: {
        createToken() {
            xeonserv().post('/teamspeak-servers/' + this.$route.params.id + '/tokens', { description: this.description, group_id: this.groupId }).then(() => {
                this.open = false
                this.$refs.table.$emit('reloaddata')
                this.description = ''
            })
        },
        deleteToken(token) {
            xeonserv().delete('/teamspeak-servers/' + this.$route.params.id + '/tokens/' + token).then(() => this.$refs.table.$emit('reloaddata'))
        }
    }
}
</script>