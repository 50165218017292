<template>
    <datatable class="mt-5" ref="table" nopagination :path="'/teamspeak-servers/' + $route.params.id + '/backups'" :headers="[
        {text: 'ID', value: 'id'},
        {text: 'Beschreibung', value: 'description'},
        {text: 'Erstellt am', value: 'created_at'},
        {text: 'Aktionen', value: 'actions'}
    ]">
        <template v-slot:actions>
            <v-dialog max-width="600px" v-model="open">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on">Neues Backup</v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline">Neues Backup</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-text-field label="Beschreibung" v-model="description" />
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="open = false">Abbrechen</v-btn>
                        <v-btn @click="createBackup()" color="primary">Erstellen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-btn small color="primary" class="mr-1 white--text" @click="restoreBackup(item.id)">Wiederherstellen</v-btn>
            <v-btn small color="red" class="white--text" @click="deleteBackup(item.id)">Löschen</v-btn>
        </template>
    </datatable>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'

export default {
    data() {
        return {
            open: false,
            description: ''
        }
    },
    methods: {
        createBackup() {
            xeonserv().post('/teamspeak-servers/' + this.$route.params.id + '/backups', { description: this.description }).then(() => {
                this.open = false
                this.$refs.table.$emit('reloaddata')
                this.description = ''
            })
        },
        deleteBackup(id) {
            xeonserv().delete('/teamspeak-servers/' + this.$route.params.id + '/backups/' + id).then(() => this.$refs.table.$emit('reloaddata'))
        },
        restoreBackup(id) {
            xeonserv().post('/teamspeak-servers/' + this.$route.params.id + '/backups/' + id + '/restore').then(() => this.$refs.table.$emit('reloaddata'))
        }
    }
}
</script>