<template>
    <v-dialog max-width="600px" v-model="open">
        <template v-slot:activator="{ on, attrs }">
            <v-btn block class="mb-2" color="primary" v-bind="attrs" v-on="on">Up-/Downgraden</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Teamspeak Server Up/Downgrade</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-text-field v-model="slots" type="number" label="Slots" min="1" max="200" />
                    <div class="text-center mt-3 mb-3" style="font-size: 25px; font-weight: 500;">
                        Preis: {{ price.toFixed(2).replace('.', ',') }} €
                    </div>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="open = false">Abbrechen</v-btn>
                <v-btn v-if="price >= 0" @click="upgrade()" color="primary">Kostenpflichtig Upgraden</v-btn>
                <v-btn v-else @click="upgrade()" color="primary">Downgraden</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'

export default {
    props: {
        server: {
            type: Object
        },
        service: {
            type: Object
        }
    },
    data() {
        return {
            open: false,
            pricing: {
                base: 0,
                slots: 0
            },
            slots: this.server.slots
        }
    },
    created() {
        xeonserv().get('/pricing/TEAMSPEAK_SERVER').then(res => this.pricing = res.data.data)
    },
    computed: {
        price() {
            const duration = (new Date(this.service.expire_at).getTime() - new Date().getTime()) / 86400000
            const diff = this.slots - this.server.slots
            return (diff * this.pricing.slots) * (duration / 30)
        }
    },
    methods: {
        upgrade() {
            xeonserv().put('/teamspeak-servers/' + this.server.id, {
                slots: this.slots
            }).then(res => {
                this.$parent.$parent.$parent.$parent.$emit('setserver', res.data.data)
                this.$store.dispatch('loadCurrentUser')
                this.open = false
            })
        }
    },
    watch: {
        server() {
            this.slots = this.server.slots
        }
    }
}
</script>