var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('datatable',{ref:"table",staticClass:"mt-5",attrs:{"nopagination":"","path":'/teamspeak-servers/' + _vm.$route.params.id + '/backups',"headers":[
    {text: 'ID', value: 'id'},
    {text: 'Beschreibung', value: 'description'},
    {text: 'Erstellt am', value: 'created_at'},
    {text: 'Aktionen', value: 'actions'}
]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Neues Backup")])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Neues Backup")])]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"label":"Beschreibung"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.open = false}}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createBackup()}}},[_vm._v("Erstellen")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1 white--text",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.restoreBackup(item.id)}}},[_vm._v("Wiederherstellen")]),_c('v-btn',{staticClass:"white--text",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteBackup(item.id)}}},[_vm._v("Löschen")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }