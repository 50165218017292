<template>
    <v-row class="mt-5">
        <v-col md="8">
            <v-row dense>
                <v-col md="12">
                    <v-text-field label="Name" outlined readonly dense :value="status.name" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Status" outlined readonly dense :value="displayStatus" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Slots" outlined readonly dense :value="status.clients.length + ' / ' + server.slots" />
                </v-col>
                <v-col md="12">
                    <v-text-field label="Restlaufzeit" outlined readonly dense :value="remainingTime" />
                </v-col>
            </v-row>
        </v-col>
        <v-col md="4">
            <v-btn block v-if="status.status == 'OFFLINE' || status.status == 'ONLINE_VIRTUAL'" class="mb-2 white--text" color="green" @click="startServer()">Starten</v-btn>
            <v-btn block v-if="status.status == 'ONLINE'" class="mb-2 white--text" color="red" @click="stopServer()">Herunterfahren</v-btn>
            <v-btn block class="mb-2" color="primary">Verlängern</v-btn>
            <TeamspeakServerUpgrade :server="server" :service="service" />
        </v-col>
    </v-row>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'
import interval from '../../../util/interval'
import TeamspeakServerUpgrade from './TeamspeakServerUpgrade'

export default {
    components: { TeamspeakServerUpgrade },
    data() {
        return {
            now: new Date()
        }
    },
    timers: {
        updateNow: { time: 1000, autostart: true, repeat: true, callback() { this.now = new Date() }}
    },
    props: {
        server: {
            type: Object
        },
        status: {
            type: Object
        },
        service: {
            type: Object
        }
    },
    methods: {
        startServer() {
            xeonserv().post('/teamspeak-servers/' + this.server.id + '/start').then(() => this.$parent.$emit('reloadstatus'))
        },
        stopServer() {
            xeonserv().post('/teamspeak-servers/' + this.server.id + '/stop').then(() => this.$parent.$emit('reloadstatus'))
        }
    },
    computed: {
        displayStatus() {
            if(this.service.expired)
                return 'Abgelaufen'
            switch(this.status.status) {
                case 'ONLINE':
                    return 'Online'
                case 'ONLINE_VIRTUAL':
                case 'OFFLINE':
                    return 'Offline'
            }
            return 'Unbekannt'
        },
        remainingTime() {
            this.now;
            return interval.format(interval.until(this.service.expire_at))
        }
    }
}
</script>