<template>
    <div class="px-page mt-5">
        <v-tabs fixed-tabs v-model="tab">
            <v-tab>
                Allgemein
            </v-tab>
            <v-tab>
                Berechtigungsschlüssel
            </v-tab>
            <v-tab>
                Backups
            </v-tab>
            <v-tab>
                Live-Ansicht
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <TeamspeakServerGeneral :server="server" :service="service" :status="status" />
            </v-tab-item>
            <v-tab-item>
                <TeamspeakServerTokens class="mt-5" :groups="groups" />
            </v-tab-item>
            <v-tab-item>
                <TeamspeakServerBackups />
            </v-tab-item>
            <v-tab-item>
                <TeamspeakViewer class="mt-5" :channels="channels" :clients="status.clients" :name="status.name" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'
import TeamspeakServerGeneral from './TeamspeakServerGeneral'
import TeamspeakServerTokens from './TeamspeakServerTokens'
import TeamspeakServerBackups from './TeamspeakServerBackups'
import TeamspeakViewer from '../../../components/tsviewer/TeamspeakViewer'

export default {
    components: { TeamspeakServerGeneral, TeamspeakServerBackups, TeamspeakServerTokens, TeamspeakViewer },
    data() {
        return {
            tab: 0,
            groups: [],
            channels: [],
            server: {
                id: this.$route.params.id,
                slots: 0
            },
            service: {
                id: 0,
                expired: false
            },
            status: {
                name: '',
                status: 'UNKNOWN',
                clients: []
            },
            statusTimer: undefined
        }
    },
    created() {
        xeonserv().get('/teamspeak-servers/' + this.server.id).then(res => this.server = res.data.data)
        xeonserv().get('/teamspeak-servers/' + this.server.id + '/service').then(res => this.service = res.data.data)
        this.loadGroupsAndChannels()
        this.loadStatus()
        this.statusTimer = setInterval(this.loadStatus, 5000)
        this.$on('reloadstatus', this.loadStatus)
        this.$on('setserver', server => {
            console.log("Hit")
            this.server = server
        })
    },
    beforeDestroy() {
        clearInterval(this.statusTimer)
    },
    methods: {
        loadStatus() {
            xeonserv().get('/teamspeak-servers/' + this.server.id + '/status').then(res => this.status = res.data.data)
        },
        loadGroupsAndChannels() {
            xeonserv().get('/teamspeak-servers/' + this.server.id + '/groups').then(res => {
                this.groups = res.data.data
            })
            xeonserv().get('/teamspeak-servers/' + this.server.id + '/channels').then(res => {
                this.channels = res.data.data
            })
        }
    }
}
</script>