<template>
    <div>
        <div @click="collapsed=!collapsed" style="cursor: pointer; user-select: none;"><img style="height: 1em;" src="../../assets/img/tsviewer/channel.png" /> {{channel.name}}</div>
        <div style="padding-left: 1.1em;" v-if="!collapsed">
            <div v-for="cl in channel.clients" :key="cl.id" style="user-select: none;"><img style="height: 1em;" src="../../assets/img/tsviewer/client.png" /> <span style="user-select: text;">{{cl.name}}</span></div>
            <TeamspeakViewerChannel v-for="ch in channel.channels" :key="ch.id" :channel="ch" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TeamspeakViewerChannel',
    props: ['channel'],
    data(){
        return {
            collapsed: false
        }
    }
}
</script>